var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "excel-view", style: _vm.backCss }, [
    _c("div", { staticClass: "excel-view-letf" }, [
      _c(
        "div",
        { staticClass: "excel-top" },
        [
          _c(
            "a-select",
            {
              staticStyle: { width: "100%", position: "relative" },
              attrs: {
                showSearch: "",
                allowClear: "",
                disabled: _vm.radioValue == 3,
                showArrow: "",
                placeholder: "请人员昵称",
                "option-filter-prop": "label",
              },
              on: { change: _vm.userSelect, search: _vm.userSearch },
              model: {
                value: _vm.selectUserId,
                callback: function ($$v) {
                  _vm.selectUserId = $$v
                },
                expression: "selectUserId",
              },
            },
            _vm._l(
              _vm.searchUsers.filter((item) => !item.enabled),
              function (user) {
                return _c(
                  "a-select-option",
                  { key: user.userId, attrs: { label: user.userName } },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          width: "100%",
                          "line-height": "30px",
                          display: "flex",
                          color: "rgba(0, 0, 0, 0.65)",
                          "justify-content": "center",
                          "align-items": "center",
                        },
                        attrs: { target: "_blank" },
                      },
                      [
                        _c("img", {
                          staticStyle: {
                            width: "20px",
                            height: "20px",
                            "border-radius": "50%",
                          },
                          attrs: { src: user.avatar, alt: "" },
                        }),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              width: "100px",
                              "margin-left": "10px",
                            },
                          },
                          [_vm._v(_vm._s(user.userName))]
                        ),
                      ]
                    ),
                  ]
                )
              }
            ),
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "tree-list" },
        [
          _c(
            "a-spin",
            {
              staticStyle: { "min-height": "70px" },
              attrs: { spinning: _vm.treeLoading },
            },
            [
              _c("a-tree", {
                attrs: {
                  expandedKeys: _vm.expandedKeys,
                  "tree-data": _vm.treeData,
                },
                on: { expand: _vm.expand, select: _vm.treeSelect },
                scopedSlots: _vm._u([
                  {
                    key: "title",
                    fn: function ({ title }) {
                      return [
                        title.indexOf(_vm.searchValue) > -1
                          ? _c("span", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    title.substr(
                                      0,
                                      title.indexOf(_vm.searchValue)
                                    )
                                  ) +
                                  "\n              "
                              ),
                              _c("span", { style: "color:" + _vm.color }, [
                                _vm._v(_vm._s(_vm.searchValue)),
                              ]),
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    title.substr(
                                      title.indexOf(_vm.searchValue) +
                                        _vm.searchValue.length
                                    )
                                  ) +
                                  "\n            "
                              ),
                            ])
                          : _c("span", [_vm._v(_vm._s(title))]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "excel-view-right" }, [
      _c("div", { staticClass: "botton-list excel-top" }, [
        _c("div", { staticClass: "radio-div" }, [
          _c(
            "span",
            {
              style:
                _vm.radioValue == 1
                  ? "color:" + _vm.color + ";border-color:" + _vm.color
                  : "border-color:rgba(0,0,0,0);color:rgba(0,0,0,0.65)",
              on: {
                click: function ($event) {
                  return _vm.changeRadio(1)
                },
              },
            },
            [_vm._v("考核")]
          ),
          _c(
            "span",
            {
              directives: [
                {
                  name: "has",
                  rawName: "v-has",
                  value: "capacityExcel:view",
                  expression: "'capacityExcel:view'",
                },
              ],
              style:
                _vm.radioValue == 2
                  ? "color:" +
                    _vm.color +
                    ";margin-left:20px;border-color:" +
                    _vm.color
                  : "margin-left:20px;border-color:rgba(0,0,0,0);color:rgba(0,0,0,0.65)",
              on: {
                click: function ($event) {
                  return _vm.changeRadio(2)
                },
              },
            },
            [_vm._v("产能")]
          ),
          _c(
            "span",
            {
              directives: [
                {
                  name: "has",
                  rawName: "v-has",
                  value: "sumbmitExcel:view",
                  expression: "'sumbmitExcel:view'",
                },
              ],
              style:
                _vm.radioValue == 3
                  ? "color:" +
                    _vm.color +
                    ";margin-left:20px;border-color:" +
                    _vm.color
                  : "margin-left:20px;border-color:rgba(0,0,0,0);color:rgba(0,0,0,0.65)",
              on: {
                click: function ($event) {
                  return _vm.changeRadio(3)
                },
              },
            },
            [_vm._v("交稿")]
          ),
          _c(
            "span",
            {
              directives: [
                {
                  name: "has",
                  rawName: "v-has",
                  value: "sentence:view",
                  expression: "'sentence:view'",
                },
              ],
              style:
                _vm.radioValue == 4
                  ? "color:" +
                    _vm.color +
                    ";margin-left:20px;border-color:" +
                    _vm.color
                  : "margin-left:20px;border-color:rgba(0,0,0,0);color:rgba(0,0,0,0.65)",
              on: {
                click: function ($event) {
                  return _vm.changeRadio(4)
                },
              },
            },
            [_vm._v("佳句")]
          ),
        ]),
        _c(
          "div",
          [
            _c(
              "a-radio-group",
              {
                staticStyle: { "margin-left": "30px" },
                on: { change: _vm.viewChange },
                model: {
                  value: _vm.timeView,
                  callback: function ($$v) {
                    _vm.timeView = $$v
                  },
                  expression: "timeView",
                },
              },
              [
                _c("a-radio-button", { attrs: { value: "WEEK" } }, [
                  _vm._v("\n            周\n          "),
                ]),
                _c("a-radio-button", { attrs: { value: "MONTH" } }, [
                  _vm._v("\n            月\n          "),
                ]),
                _c("a-radio-button", { attrs: { value: "YEAR" } }, [
                  _vm._v("\n            年\n          "),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("div", [
        _vm.radioValue == 1
          ? _c("div", [
              _vm.tableView == "excel"
                ? _c(
                    "div",
                    [
                      _c("kpi-excel", {
                        ref: "kpiExcel",
                        attrs: {
                          groupType: _vm.groupType,
                          positionId: _vm.positionId,
                          departCode: _vm.departCode,
                          selectUser: _vm.selectUser,
                          timeView: _vm.timeView,
                          height: _vm.height,
                        },
                      }),
                    ],
                    1
                  )
                : _c("div", [_vm._v("考核-图-" + _vm._s(_vm.timeView))]),
            ])
          : _vm.radioValue == 2
          ? _c("div", [
              _vm.tableView == "excel"
                ? _c(
                    "div",
                    [
                      _c("capacity-excel", {
                        ref: "capacityExcel",
                        attrs: {
                          groupType: _vm.groupType,
                          positionId: _vm.positionId,
                          departCode: _vm.departCode,
                          selectUser: _vm.selectUser,
                          timeView: _vm.timeView,
                          height: _vm.height,
                        },
                      }),
                    ],
                    1
                  )
                : _c("div", [_vm._v("产能-图-" + _vm._s(_vm.timeView))]),
            ])
          : _vm.radioValue == 3
          ? _c("div", [
              _vm.tableView == "excel"
                ? _c(
                    "div",
                    [
                      _c("submit-excel", {
                        ref: "submitExcel",
                        attrs: {
                          groupType: _vm.groupType,
                          positionId: _vm.positionId,
                          departCode: _vm.departCode,
                          selectUser: _vm.selectUser,
                          timeView: _vm.timeView,
                          platformId: _vm.platformId,
                          height: _vm.height,
                        },
                      }),
                    ],
                    1
                  )
                : _c("div", [_vm._v("交稿-图-" + _vm._s(_vm.timeView))]),
            ])
          : _vm.radioValue == 4
          ? _c("div", [
              _vm.tableView == "excel"
                ? _c(
                    "div",
                    [
                      _c("sentence-excel", {
                        ref: "sentenceExcel",
                        attrs: {
                          groupType: _vm.groupType,
                          positionId: _vm.positionId,
                          departCode: _vm.departCode,
                          selectUser: _vm.selectUser,
                          timeView: _vm.timeView,
                          platformId: _vm.platformId,
                          height: _vm.height,
                        },
                      }),
                    ],
                    1
                  )
                : _c("div", [_vm._v("交稿-图-" + _vm._s(_vm.timeView))]),
            ])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }